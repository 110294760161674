import React, {FC, useEffect, useRef} from "react";

// @ts-ignore
const {WordCloud} = window.charts;

interface IProps {
  wordCloudConfig: any
  handleSetEvent: Function
}

export const IWordCloud: FC<IProps> = ({wordCloudConfig, handleSetEvent, ...rest}) => {
  const wordCloudRef = useRef<any>()

  useEffect(() => {
    if (wordCloudRef.current) {
      const chart = wordCloudRef.current.getChart()
      chart.on('element:click', (e: any) => {
        handleSetEvent(e)
      })
    }

  }, [])

  return wordCloudConfig.data ? <WordCloud ref={wordCloudRef} {...wordCloudConfig} /> : null
}

