import React from "react";
import {ITagItem} from "../";
import {Tooltip} from "antd";


interface IProps {
  tags?: Array<any>
}

export const ITagList = ({tags = [], ...rest}: IProps) => {
  return tags && tags.map((item: any) => {
    let isLongTag = item.length > 8
    const tagElem = <ITagItem style={{marginBottom: 3}} key={item}>
      {isLongTag ? `${item.slice(0, 8)}...` : item}
    </ITagItem>
    return isLongTag ? <Tooltip title={item} key={item}>{tagElem}</Tooltip> : tagElem;
  })
}

