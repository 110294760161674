import React from "react";
import {Spin} from "antd";
import './index.css'

export const ILoading = ({msg}: { msg: string }) => {
  return <div className="loading">
    <Spin/>
    <span> {msg} </span>
  </div>
}
