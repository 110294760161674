import {RowProps} from "antd/es/row";


// 适配
// webLayout 页面
export const contentLeftLayout = {
  sm: 24,
  md: 24,
  xl: 16,
}

export const contentRightLayout = {
  sm: 24,
  md: 0,
  xl: {
    // offset: 1,
    span: 8
  },
}

export const commonRowAttr: RowProps = {
  gutter: [18, 18]
}

export const articleMetaLayout = {
  xs: 12,
  sm: 12,
  md: 12,
  xl: 12,
}

export const linksLayout = {
  xs: 12,
  sm: 8,
  md: 4,
  xl: 6,
}
export const booksLayout = {
  xs: 12,
  sm: 8,
  md: 6,
  xl: 8,
}
