import {ArticleCategory} from "../../services/articleCategory";
import React from "react";

export const ICategoryTagItem = (item: ArticleCategory, handleSetCondition: Function) => {
  let data = {
    categoryId: item.id
  }
  return <li className="item" key={item.id} onClick={() => handleSetCondition(data)}>
    <div>{item.title} <span className="item-number">{item.count}</span></div>
  </li>
}
