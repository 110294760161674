import {ResponseData, usePost} from "../utils/http";
import {useAsync} from "../hooks";
import {useEffect} from "react";

export interface ArticleTag {
  id: number
  title: string
  color: string
  status: number
  createdAt: string
}

interface getArticleTagsParams {

}

const client = usePost()
export const useArticleTagsByService = (params: any) => {
  const {run, ...result} = useAsync<ResponseData<ArticleTag[]>>()

  useEffect(() => {
    run(client<ArticleTag[]>("web/article_tag/index", params))
  }, [params])

  return result
}

