import {Pages, ResponseData} from "../../utils/axios";
import {useCallback, useEffect, useReducer} from "react";

enum actionTypes {
  // 设置loading
  SET_LOADING = "SET_LOADING",
  // 设置 dataSource
  SET_DATA_SOURCE = "SET_DATA_SOURCE",
  // 设置 pages
  SET_PAGES = "SET_PAGES",
  // 设置 搜索数据
  SET_SEARCH_DATA = "SET_SEARCH_DATA"
}

const reducer = (state: initStateProps, action: { type: actionTypes, data: any }) => {
  const {type, data} = action
  switch (type) {
    case actionTypes.SET_LOADING:
      return {...state, ...{loading: data}}
    case actionTypes.SET_DATA_SOURCE:
      return {...state, ...{dataSource: data}}
    case actionTypes.SET_PAGES:
      return {...state, ...{pages: data}}
    case actionTypes.SET_SEARCH_DATA:
      return {...state, ...{params: data}}
    default:
      return state
  }
}

interface initStateProps {
  loading?: boolean
  order?: string
  field?: any
  dataSource?: any
  params?: {}
  pages?: Pages | false
}

export const DEFAULT_STATE: initStateProps = {
  loading: false,
  order: undefined,
  field: undefined,
  dataSource: [],
  params: {},
  pages: false
}

export interface IProps<T> {
  api: T
  initState?: initStateProps
}

// todo 封装数据有问题 会导致多次请求
// todo 外部只需要提供一个方法 在内部在去实现请求逻辑
type useTableFuncTypes = <T extends (value: any) => Promise<ResponseData<any>>>(props: IProps<T>) => {
  handleSetCondition: (condition: any) => {},
  handleSetPage: (props: Pages) => any,
  loading: Boolean,
  dataSource: any,
  pagination: Pages | false
}

const useRequest: useTableFuncTypes = ({api, initState}) => {
  let cancel = false

  const [{
    loading, // 加载态
    // order, // 排序方向
    // field, // 排序字段
    dataSource, // 数据
    params, // 额外搜索项
    pages
  }, dispatch] = useReducer(reducer, {
    ...DEFAULT_STATE,
    ...initState
  })

  const IDispatch = useCallback(async (type: actionTypes, data: any) => {
    return dispatch({type, data})
  }, [])

  const core = async () => {
    await IDispatch(actionTypes.SET_LOADING, true)

    let condition = {...params, ...pages}

    let res = await api(condition)
    if (res) {
      const {result, page} = res
      await IDispatch(actionTypes.SET_DATA_SOURCE, result)

      Object.keys(page).length && await IDispatch(actionTypes.SET_PAGES, page)
    }

    await IDispatch(actionTypes.SET_LOADING, false)
  }

  useEffect(() => {
    cancel = false

    !cancel && core()

    return () => {
      cancel = true
    }
  }, [api, params, pages.current])

  // 设置条件
  const handleSetCondition = async (data = []) => {
    await IDispatch(actionTypes.SET_SEARCH_DATA, data)
  }

  // 重置page
  const handleSetPage = async (page: Pages = {...pages, current: 1}) => {
    (!loading && pages) && await IDispatch(actionTypes.SET_PAGES, page)
  }


  return {
    handleSetCondition,
    handleSetPage,
    loading,
    dataSource,
    pagination: pages && pages,
  }
}

export default useRequest
