import Axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import Qs from "qs";
import {SUCCESS_CODE, SUCCESS_HTTP_STATUS, TOKEN_ERROR_CODE, TOKEN_TIMEOUT_CODE} from "../config/errorCode";
import {IMessage, messageEnum} from "../components";


const apiUrl = process.env.REACT_APP_API_URL

export interface Pages {
  current: number,
  pageSize: number,
  total: number
}

export interface ResponseData<T = []> {
  code: number,
  message: string,
  result: T,
  page: Pages
  reqId: string
}

const instance = Axios.create({
  timeout: 5000
})

instance.interceptors.request.use((config) => {
  if (config.method?.toLocaleUpperCase() === "POST") {

  }

  return config
})


instance.interceptors.response.use(
  (response: AxiosResponse) => {
    const {data, status} = response
    const {code, message} = data
    if (status === SUCCESS_HTTP_STATUS) {
      switch (code) {
        case SUCCESS_CODE:
          return Promise.resolve(response.data)
          break
        case TOKEN_ERROR_CODE :
        case TOKEN_TIMEOUT_CODE :
          // todo token 验证失败
          // store.dispatch<any>(logout())
          IMessage(message, messageEnum.ERROR)
          break
        default:
          console.log("验证失败")
          IMessage(message, messageEnum.ERROR)
          break
      }
    } else {
      IMessage(message, messageEnum.ERROR)
    }
    return Promise.reject(message)
  },
  error => {
    return Promise.reject(error)
  }
)

interface Config extends AxiosRequestConfig {
  token?: string;
}

interface useHttpProps {
  post: <T>(url: string, params: any, config?: AxiosRequestConfig) => Promise<ResponseData<T>>
}

export const usePost = () => {
  return <T>(url: string, params?: any, config?: AxiosRequestConfig) =>
    instance.post<T, ResponseData<T>>(url, Qs.stringify(params), {baseURL: apiUrl, ...config})
}



