import React, {FC} from "react";
import {Col, Row} from "antd";
import {CalendarOutlined, UserOutlined} from "@ant-design/icons/lib";
import './IArticleMeta.css'


interface IProps {
  name: string
  createdAt: string
}

export const IArticleMeta: FC<IProps> = ({name, createdAt, ...rest}) => {
  return <Row className="article-meta-wrapper" gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
    <Col>
      <span className="author"><UserOutlined/> {name}</span>
    </Col>
    <Col>
      <span className="time"><CalendarOutlined/> {createdAt}</span>
    </Col>
  </Row>
}
