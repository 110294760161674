import React, {useContext} from "react";
import "./ICloudTagCard.css"
import {ICard, ICloudTagItem} from "../";
import {CommonDataContext} from "../../hooks/useRedux";
import {ArticleTag} from "../../services/articleTag";


export const ICloudTagCard = () => {

  const {tags} = useContext(CommonDataContext)

  return <div className="cloud-tag-card-wrapper">
    <ICard>
      <h2 className="title">标签</h2>
      <div className="cloud-tag">
        <ul>
          {
            tags.map((item: ArticleTag) => {
              return ICloudTagItem(item)
            })
          }
        </ul>
      </div>
    </ICard>
  </div>
}
