import React, {FC} from "react";
import {TagProps} from "antd/es/tag";
import {Tag} from "antd"

interface IProps {

}

export const ITagItem: FC<IProps & TagProps> = ({children, ...rest}) => {
  return <Tag {...rest}>{children}</Tag>
}
