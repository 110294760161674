import React, {FC, Suspense} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import {ILoading} from "../components";
import BaseLayout from "./BaseLayout";
import ArticleDetailLayout from "./ArticleDetailLayout";
import CommonLayout from "./CommonLayout";
import SearchLayout from "./SearchLayout";
import ICarouselLayout from "./ICarouselLayout";
import NotFound from "../pages/Error/NotFound";


const Home = React.lazy(/* webpackChunkName: "Home" */ () => import('../containers/HomeContainer'))
const ArticleDetail = React.lazy(/* webpackChunkName: "ArticleDetail" */ () => import('../containers/ArticleDetailContainer'))
const Articles = React.lazy(/* webpackChunkName: "Articles" */ () => import('../containers/ArticlesContainer'))
const Category = React.lazy(/* webpackChunkName: "Category" */ () => import('../containers/CategoryContainer'))
const Link = React.lazy(/* webpackChunkName: "Link" */ () => import('../containers/LinkContainer'))
const About = React.lazy(/* webpackChunkName: "About" */ () => import('../containers/AboutContainer'))
const Books = React.lazy(/* webpackChunkName: "Books" */ () => import('../containers/BooksContainer'))

interface IProps {
}

const ERouter: FC<IProps> = (props) => {
  return <BrowserRouter>
    <Switch>
      <BaseLayout>
        <Suspense fallback={<ILoading msg={"加载中，请稍后 ..."}/>}>
          <Switch>

            <Route path="/" exact component={() => <ICarouselLayout><Home/></ICarouselLayout>}/>


            <Route path="/article/:id" exact component={() => {
              return <ArticleDetailLayout><Route path="/article/:id" component={ArticleDetail}/></ArticleDetailLayout>
            }}/>

            <Route path="/articles" exact component={() => {
              return <SearchLayout>
                <Route path="/articles" component={Articles}></Route>
              </SearchLayout>
            }}/>

            <Route path="/category" exact component={() => <CommonLayout><Category/></CommonLayout>}/>

            <Route path="/About" exact component={() => <CommonLayout><About/></CommonLayout>}/>

            <Route path="/links" exact component={() => <CommonLayout><Link/></CommonLayout>}/>

            <Route path="/books" exact component={() => <CommonLayout><Books/></CommonLayout>}/>

            <Route path="/home" exact component={() => <ICarouselLayout><Home/></ICarouselLayout>}/>

            <Route path="/404" exact component={() => <NotFound/>}/>

            <Redirect to="/404"/>

          </Switch>
        </Suspense>
      </BaseLayout>
    </Switch>
  </BrowserRouter>

}

export default ERouter
