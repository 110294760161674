import React, {FC, useEffect, useReducer, useState} from "react";
import {IFooter, IHeader} from "../components";
import {Layout} from "antd";
import {CommonDataContext, handleGetTags, initState, reducer} from "../hooks/useRedux";
import {useArticleTagsByService} from "../services/articleTag";
import useDebounce from "../hooks/useDebounce";

interface IProps {

}

const BaseLayout: FC<IProps> = ({children, ...rest}) => {

  const [state, dispatch] = useReducer(reducer, initState)

  const [params, setParams] = useState();
  const {isLoading, data} = useArticleTagsByService(useDebounce(params))
  useEffect(() => {
    data && dispatch(handleGetTags(data.result))
  }, [isLoading])


  return <Layout
    className="web-layout-wrapper"
    style={{minHeight: "100vh", background: "rgba(241,241,241,0.5)"}}
  >
    <IHeader/>
    <CommonDataContext.Provider value={state}>
      {children}
    </CommonDataContext.Provider>
    <IFooter style={{opacity: 0.8}}/>
  </Layout>
}

export default BaseLayout
