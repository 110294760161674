import React, {FC} from "react";
import {Result, Button} from 'antd';
import {Link} from "react-router-dom";


interface IProps {

}

const NotFound: FC<IProps> = () => {
  return <Result
    style={{height: "85vh"}}
    status="404"
    title="404"
    subTitle="糟糕！页面飞到外太空了！"
    extra={<Button type="primary"><Link to="/">Back Home</Link></Button>}
  />
}

export default NotFound
