import React, {FC} from "react";
import {commonRowAttr} from "../utils/layout";
import {Col, Layout, Row} from "antd";

interface IProps {

}

const {Content} = Layout;

const ArticleDetailLayout: FC<IProps> = ({children, ...rest}) => {
  return <Content className="content-wrapper" style={{maxWidth: 1100, width: "100%", margin: '24px auto'}}>
    <Row {...commonRowAttr}>
      <Col
        span={24}
        className="left-box"
        style={{padding: "0 15px"}}
      >
        {children}
      </Col>
    </Row>
  </Content>
}


export default ArticleDetailLayout
