import React from "react";
import {Carousel} from "antd";

const commonType = {
  height: '500px',
  color: '#fff',
  lineHeight: '500px',
  textAlign: 'center',
  opacity: 0.9,
  backgroundPosition: "50% center",
  backgroundSize: "cover"
}

const contentBg: any = [
  {
    background: "url('static/images/1.jpg')",
  },
  {
    background: "url('static/images/2.jpg')",
  },
  {
    background: "url('static/images/3.jpg')",
  }
];

export const ICarousel = () => {
  return <>
    <Carousel autoplay>
      {
        contentBg && contentBg.map((item: any, index: number) => {
          return <div key={index}>
            <h3 style={{...commonType, ...item}}>
              暂时还没想好banner怎么弄
            </h3>
          </div>
        })
      }
    </Carousel>
  </>
}
