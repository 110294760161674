import React, {FC, useEffect, useRef} from "react";
import {ArticleCategory} from "../../services/articleCategory";

// import {RadarConfig} from "@ant-design/charts/es/radar";

// @ts-ignore
const {Radar} =  window.charts;

interface IProps {
  data: Array<ArticleCategory>
  handleSetCondition: Function
}

export const IRadar: FC<IProps> = ({data, handleSetCondition}) => {
  const wordCloudRef = useRef<any>()

  useEffect(() => {
    if (wordCloudRef.current) {
      const chart = wordCloudRef.current.getChart()
      chart.on('element:click', (e: any) => {
        let data = {
          categoryId: e.data.data.id
        }
        handleSetCondition(data)
      })
    }
  }, [])

  const config: any = {
    data,
    width: 300,
    height: 300,
    xField: 'title',
    yField: 'count',
    meta: {count: {alias: '文章'}},
    xAxis: {
      line: null,
      tickLine: null,
      grid: {line: {style: {lineDash: null}}},
    },
    yAxis: {
      line: null,
      tickLine: null,
      grid: {
        line: {
          type: 'line',
          style: {lineDash: null},
        },
        alternateColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    point: {},
  };

  return <Radar ref={wordCloudRef} {...config} />
}
