import {Button, Result} from "antd";
import React from "react";

export const FullPageErrorFallback = ({error}: { error: Error | null }) => {
  return <Result
    status="500"
    title="500"
    subTitle={error?.message}
    extra={<Button type="primary">Back Home</Button>}
  />
}
