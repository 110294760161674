import React, {FC} from "react";
import {Empty} from 'antd';
import './index.css'

interface IProps {

}

export const IEmpty: FC<IProps> = ({children, ...rest}) => {
  return <div className="empty-wrapper">
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...rest}/>
  </div>
}
