import React from "react";
import {ICard, IIMage} from "../";
import './IWxCard.css'
import WxImg from "../../asset/images/wx.png"


export const IWxCard = () => {
  return <div className="wx-wrapper">
    <ICard>
      <h2 className="title">微信关注</h2>
      <div className="content">
        <IIMage src={WxImg} preview={false}/>
      </div>
    </ICard>
  </div>
}

