import {combineReducers} from 'redux'

import {reducer as articleReducer} from "./article"

const reducer = combineReducers({
  article: articleReducer
})


export default reducer




