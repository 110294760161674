import React, {FC} from "react";
import "./IUserCard.css"
import {Avatar} from "antd";
import img from "../../asset/images/avatar.jpg"
import {ICard} from "../";

interface IProps {

}

export const IUserCard: FC<IProps> = () => {
  return <div className="user-card-wrapper">
    <ICard>
      <h2 className="title">关于我</h2>
      <div className="avatar">
        <Avatar size={100} src={img} />
      </div>
      <div className="content">
        <p><span>站长：</span>Mr.Shen</p>
        <p><span>职业：</span>php & 前端</p>
        <p><span>邮箱：</span>564435314@qq.com</p>
      </div>
    </ICard>
  </div>
}

