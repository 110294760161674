import React, {FC} from "react";
import {Layout} from "antd"
import {BasicProps} from "antd/es/layout/layout";
import {Link} from "react-router-dom";
import './IHeader.css'

const {Header} = Layout;

interface IProps {
}

export const IHeader: FC<IProps & BasicProps> = (props) => {
  return <Header className="header-wrapper">
    <div className="header-main">
      <div className="logo-box">
        <Link to={"/"}>Mr.Shen 个人博客</Link>
      </div>
      <div className="menu-box">
        <ul className="menu">
          <li><Link to={"/"}>首页</Link></li>
          <li><Link to={"/articles"}>随笔</Link></li>
          <li><Link to={"/category"}>分类</Link></li>
          <li><Link to={"/links"}>友链</Link></li>
          <li><Link to={"/books"}>书单</Link></li>
          <li><Link to={"/about"}>关于</Link></li>
        </ul>
      </div>
    </div>
  </Header>
}
