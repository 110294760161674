import {useEffect} from "react";


function useDocumentTitle(title: string) {
  useEffect(() => {
      document.title = title;
      return () => {
        document.title = "Mr.Shen博客,个人测试经验交流,"
      }
    }, [title]
  );
}

export default useDocumentTitle
