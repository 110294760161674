import React, {FC} from "react";
import {Pagination} from "antd";
import {Pages} from "../../utils/axios";


interface IProps {
  pagination: Pages | false
  handleOnChange: (number: number) => void
}

export const IPagination: FC<IProps> = ({pagination, handleOnChange}) => {
  return <Pagination
    showSizeChanger={false}
    hideOnSinglePage={true}
    onChange={(current) => handleOnChange(current)}
    {...pagination}
  />
}

