import React, {FC} from "react";
import {Input} from "antd";
import './ISearchCard.css'
import {ICard} from "../";

const {Search} = Input;

interface IProps {

}

export const ISearchCard: FC<IProps> = () => {
  return <div className="search-card-wrapper">
    <ICard>
      <Search className="search" placeholder="搜索" onSearch={(value: any) => console.log(value)} enterButton/>
    </ICard>
  </div>
}

