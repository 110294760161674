import React from "react";
import "./ICloudTagCard.css"
import {Link} from 'react-router-dom'

import {ArticleTag} from "../../services/articleTag";

export const ICloudTagItem = (item: ArticleTag) => {
  return <li className="item" key={item.id}>
    <Link to="/home">{item.title}</Link>
  </li>
}
