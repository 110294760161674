import React, {FC} from "react";

import {Card} from "antd";
import {CardProps} from "antd/es/card";

interface IProps {

}

export const ICard: FC<IProps & CardProps & React.HTMLAttributes<HTMLDivElement>> = ({children, ...rest}) => {
  return <Card {...rest}>
    {children}
  </Card>
}
