import {Reducer, useCallback, useEffect, useReducer, useState} from "react";
import {Pages, usePost} from "utils/http";

interface initStateProps<T> {
  loading: boolean
  order: any
  field: any
  dataSource: T
  params: {}
}

enum actionTypes {
  // 设置loading
  SET_LOADING = "SET_LOADING",
  // 设置 dataSource
  SET_DATA_SOURCE = "SET_DATA_SOURCE",
  // 设置 pages
  SET_PAGES = "SET_PAGES",
  // 设置 搜索数据
  SET_SEARCH_DATA = "SET_SEARCH_DATA",
  // 重置
  RESET = "RESET"
}

const reducer = <T>(state: initStateProps<T>, action: { type: actionTypes, payload: any }) => {
  const {type, payload} = action
  switch (type) {
    case actionTypes.SET_LOADING:
      return {...state, ...{loading: payload}}
    case actionTypes.SET_DATA_SOURCE:
      return {...state, ...{dataSource: payload}}
    case actionTypes.SET_PAGES:
      return {...state, ...{params: {...state.params, ...payload}}}
    case actionTypes.SET_SEARCH_DATA:
      return {...state, ...{params: {...state.params, ...payload}}}
    case actionTypes.RESET:
      return {...state, ...payload}
    default:
      return state
  }
}

const client = usePost()

export const useAsyncTable = <T = any>(
  url: string,
  initialState?: any
) => {
  const DEFAULT_STATE: initStateProps<T[]> = {
    loading: false,
    order: {},
    field: {},
    dataSource: [],
    params: {},
  }

  const [pagination, setPagination] = useState<Pages>({
    current: 1,
    pageSize: 6,
    total: 0
  })

  const [{
    loading,
    dataSource,
    params,
  }, dispatch] = useReducer<Reducer<initStateProps<T>, any>>(
    reducer, {
      ...DEFAULT_STATE,
      ...initialState
    })

  const core = async () => {
    dispatch({type: actionTypes.SET_LOADING, payload: true})

    let condition = {...params}

    const res = await client<T>(url, condition)

    if (res) {
      const {result, page} = res
      await dispatch({type: actionTypes.SET_DATA_SOURCE, payload: result})
      await setPagination(page);
    }

    dispatch({type: actionTypes.SET_LOADING, payload: false})
  }

  const coreWarp = useCallback(core, [url, params])

  useEffect(() => {
    coreWarp()
  }, [coreWarp])

  // 改变分页
  const handleChangePages = (pages: { current: number, pageSize?: number }) => {
    dispatch({
      type: actionTypes.SET_PAGES,
      payload: pages
    })
  }

  const handleChangePageNo = (current: number) => {
    handleChangePages({current})
  }

  // 改变查询条件
  const handleChangeCondition = (data: any) => {
    dispatch({type: actionTypes.SET_SEARCH_DATA, payload: data})
  }

  const handleReset = () => {
    dispatch({type: actionTypes.RESET, payload: DEFAULT_STATE})
  }

  return {
    loading,
    dataSource,
    params,
    pagination,
    handleChangeCondition,
    handleChangePageNo,
    handleChangePages,
    handleReset
  }
}

