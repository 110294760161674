import React, {FC} from "react";
import './IArticleItem.css'
import {IArticleMeta, IIMage, ITagList} from "../";
import {Link} from "react-router-dom";
import {Article} from "../../services/article";

interface IProps {
  info: Article
}

export const IArticleItem: FC<IProps> = ({info, ...rest}) => {
  const {title, user, createdAt, description, tags, coverDetail} = info

  return <div className="article-item-wrapper">
    {
      (coverDetail && coverDetail.url) && <div className="image">
        <IIMage src={coverDetail.url} preview={false} style={{
          height: "150px",
          width: "150px",
          backgroundPosition: "50%",
          backgroundSize: "cover",
          borderRadius: "4px"
        }}/>
      </div>
    }
    <div className="content">

      <h2 className="title">
        <Link to={'/article/' + info.id}>
          {title}
        </Link>
      </h2>

      <div className="meta">
        <IArticleMeta name={user.username} createdAt={createdAt}/>
      </div>

      <div className="desc">
        {description}
      </div>

      <div className="tags">
        {
          tags && ITagList(tags)
        }
      </div>
    </div>
  </div>
}
