import React, {FC} from "react";
import Editor from "for-editor"

interface IProps {
  value: string
}

export const IArticleContent: FC<IProps> = ({value, ...rest}) => {
  return <>
    <Editor preview={true} value={value} toolbar={{}} height={"100%"}/>
  </>
}
