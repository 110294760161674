import {IAction} from "../types";
import produce from "immer";
import {actionTypes as types} from './index'
import {Article} from "../../services/article";

interface defaultState {
  list: Array<Article> | []
}

const defaultState: defaultState = {
  list: []
}

export default (state = defaultState, action: IAction) => {
  const {type, payload} = action

  return produce(state, draft => {
    switch (type) {
      case types.SET_ARTICLE_LIST:
        draft.list = payload
    }
  })
}



