import React from 'react';
import 'App.css';
import ERouter from "router/ERouter";
import {Provider} from "react-redux";
import store from "store";
import {BackTop} from "antd";
import {FullPageErrorFallback, IErrorBoundary} from "components";

function App() {
  return <Provider store={store}>
    <IErrorBoundary fallbackRender={FullPageErrorFallback}>
      <ERouter/>
      <BackTop/>
    </IErrorBoundary>
  </Provider>
}

export default App;
