import React, {FC} from "react";
import {Link} from "react-router-dom"
import {Layout} from "antd";
import './IFooter.css'

const {Footer} = Layout

interface IProps {

}

export const IFooter: FC<IProps & React.HTMLAttributes<HTMLDivElement>> = ({children, ...rest}) => {
  return <Footer style={{}} {...rest} className="footer-wrapper">
    <div>
      <div className="copyright">Copyright © <Link to="/" style={{color: "#a5a4a4"}}>Mr.Shen</Link> All Rights Reserved.
      </div>
      <div className="ip">
        <a target={"_blank"} rel="noopener noreferrer" href="https://beian.miit.gov.cn/" style={{color: "#a5a4a4"}}>苏ICP备18013734号-1</a>
      </div>
    </div>
    {children}
  </Footer>
}
