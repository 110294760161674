

export const SET_ARTICLE_LIST = "article/SET_ARTICLE_LIST"
export type  SET_ARTICLE_LIST = typeof SET_ARTICLE_LIST

export const SET_ARTICLE_INFO = "article/SET_ARTICLE_INFO"
export type  SET_ARTICLE_INFO = typeof SET_ARTICLE_INFO


export const SET_ARTICLE_PAGES = "article/SET_ARTICLE_PAGES"
export type  SET_ARTICLE_PAGES = typeof SET_ARTICLE_PAGES
