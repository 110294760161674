import React from "react";
import {Skeleton} from "antd"
import './ISKeleton.css'

export const IArticleItemSkeleton = () => {
  return <div className="article-item-skeleton-wrapper">
    <Skeleton.Avatar className="image" active shape={"square"}/>
    <div className="content">
      <Skeleton active/>
    </div>
  </div>
}

