


import React, {Component, PropsWithChildren, ReactElement,} from "react";

type FallbackRender = (props: { error: Error | null }) => ReactElement;

interface ErrorBoundaryProps {
  fallbackRender: FallbackRender;
}

interface ErrorBoundaryState {
  error: Error | null;
}

export class IErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
  state = {error: null};

  // todo 错误日志收集
  static getDerivedStateFromError(error: Error) {
    console.log(error);
    return {error};
  }

  render() {
    const {error} = this.state;
    const {fallbackRender, children} = this.props;
    if (error) {
      return fallbackRender({error});
    } else {
      return children;
    }
  }
}
