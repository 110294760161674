import React, {FC} from "react";
import {Col, Layout, Row} from "antd";
import {commonRowAttr, contentLeftLayout, contentRightLayout} from "../utils/layout";
import {ICloudTagCard, IWxCard, IUserCard, ISearchCard} from "../components";

interface IProps {

}

const {Content} = Layout;

const SearchLayout: FC<IProps> = ({children, ...rest}) => {
  return <>
    <Content className="content-wrapper" style={{maxWidth: 1100, width: "100%", margin: '24px auto'}}>
      <Row {...commonRowAttr}>
        <Col
          className="left-box"
          {...contentLeftLayout}
          style={{padding: "0 15px"}}
        >
          {children}
        </Col>

        <Col
          className="right-box"
          {...contentRightLayout}
          style={{padding: "0 15px"}}
        >
          <IUserCard/>
          <ISearchCard/>
          <ICloudTagCard/>
          <IWxCard/>
        </Col>
      </Row>
    </Content>
  </>
}

export default SearchLayout
