import React, {FC} from "react";
import {IArticleItem, IArticleItemSkeleton, IEmpty} from "../";


interface IProps {
  loading: Boolean
  dataSource: any
}

export const IArticleList: FC<IProps> = ({loading, dataSource}) => {
  return <>
    {
      (loading || dataSource.length) ? dataSource.map((item: any) => {
        return loading ? <IArticleItemSkeleton key={item.id}/>
          : <IArticleItem
            key={item.id}
            info={item}
          />
      }) : <IEmpty/>
    }
  </>
}
