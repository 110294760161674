import {ArticleTag} from "../../services/articleTag";
import React from "react";


enum actionTypes {
  SET_TAGS = "SET_TAGS"
}

export type initStateProps = {
  tags: Array<ArticleTag>
}

export const initState: initStateProps = {
  tags: []
}

export const CommonDataContext = React.createContext<initStateProps>(initState)

export const reducer = (state: initStateProps, action: { type: actionTypes, data: any }) => {
  const {type, data} = action
  switch (type) {
    case actionTypes.SET_TAGS:
      return {...state, tags: data}
    default:
      return state
  }
}

export const handleGetTags = (data: any) => {
  return {
    type: actionTypes.SET_TAGS,
    data
  }
}
